import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Sidebar from 'src/components/basic/Sidebar';
import { SelectBareme } from 'src/components/client/prejudiceFormComponents/Capitalisation/SelectBareme';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { theme } from 'src/constants/theme';
import {
  getIndemnisationBaremeSourceLabel,
  getRIIBaremeSourceNameLabel,
} from 'src/helpers/bareme/bareme';
import { getCapitalisationBaremes } from 'src/helpers/prejudices/capitalisation';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { updateOrganization } from 'src/slices/organization';
import { baremeSelectors } from 'src/store/selectors';
import { Organization } from 'src/types/organization.type';
import {
  baremeIndemnisationValueChoices,
  baremeRIIRoundChoices,
} from 'src/types/procedure.type';
import * as yup from 'yup';

type FormData = Pick<Organization, 'baremes'>;

interface Props {
  organization?: Organization;
  isSidebar?: boolean;
  isOpen: boolean | undefined;
  closeSideBar: () => void;
}
export const validationSchemaOrganization = (): yup.Schema<FormData> =>
  yup.object({
    baremes: yup.object().shape({
      baremeIndemnisationId: yup.string().nullable().optional(),
      baremeIndemnisationValueChoice: yup
        .string()
        .oneOf(baremeIndemnisationValueChoices)
        .when('baremeIndemnisationId', (baremeIndemnisationId, schema) =>
          typeof baremeIndemnisationId === 'string' &&
          baremeIndemnisationId !== 'noSelectionText'
            ? schema.required('Veuillez choisir une option pour le barème.')
            : schema.optional(),
        ),
      baremeRIIId: yup.string().nullable().optional(),
      baremeCapitalisationId: yup.string().nullable().optional(),
      baremeRIIRoundAge: yup
        .string()
        .oneOf(baremeRIIRoundChoices)
        .nullable()
        .optional(),
      baremeRIIRoundTauxDFP: yup
        .string()
        .oneOf(baremeRIIRoundChoices)
        .nullable()
        .optional(),
      forfaitJourDFTP: yup.number().nullable().optional(),
    }),
  });
export const OrganizationEdition: React.FC<Props> = ({
  organization,
  isSidebar,
  isOpen,
  closeSideBar,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { baremes } = useAppSelector((state) => ({
    baremes: baremeSelectors.selectAll(state),
  }));

  const capitalisationBaremes = useMemo(
    () => getCapitalisationBaremes(baremes, 'm'),
    [baremes],
  );
  const onSubmit = async (values: FormData) => {
    try {
      if (!organization?._id) {
        console.error('Organization ID is missing.');
        return;
      }

      await dispatch(
        updateOrganization({
          organizationId: organization._id,
          data: { ...values },
        }),
      ).unwrap();

      if (isSidebar) {
        closeSideBar();
        reset();
      } else {
        navigate(-1);
      }
    } catch (error) {
      console.error('Failed to update the organization:', error);
    }
  };

  const initialValues: FormData = {
    baremes: {
      baremeIndemnisationId:
        organization?.baremes?.baremeIndemnisationId || null,
      baremeIndemnisationValueChoice:
        organization?.baremes?.baremeIndemnisationValueChoice || null,
      baremeRIIId: organization?.baremes?.baremeRIIId || null,
      baremeCapitalisationId:
        organization?.baremes?.baremeCapitalisationId || null,
      baremeRIIRoundAge: organization?.baremes?.baremeRIIRoundAge || null,
      baremeRIIRoundTauxDFP:
        organization?.baremes?.baremeRIIRoundTauxDFP || null,
      forfaitJourDFTP: organization?.baremes?.forfaitJourDFTP || null,
    },
  };
  const { control, setValue, handleSubmit, reset, ...useFormReturn } =
    useForm<FormData>({
      defaultValues: initialValues,
      resolver: yupResolver(validationSchemaOrganization()),
      mode: 'onTouched',
    });
  useEffect(() => {
    if (isOpen) {
      reset(initialValues);
    }
  }, [isOpen]);

  const header = (
    <Typography variant="h4" textAlign="center" marginY={2}>
      {t('pages.OrganizationConfiguration.title')}
    </Typography>
  );

  const baremesIndemnisation = baremes.filter(
    (bareme) =>
      bareme.type === 'Indemnisation' &&
      ['Mornet', 'FGTI', 'ONIAM'].includes(bareme.source),
  );

  const baremesRII = baremes.filter(
    (bareme) =>
      bareme.type === 'RII' &&
      ['Mornet', 'FGTI', 'ONIAM'].includes(bareme.source),
  );

  const isDisable = (value: string) => {
    switch (value) {
      case 'minimum':
        return false;
      case 'average':
      case 'maximum':
        return baremeIndemnisation?.source === 'FGTI';
      default:
        return true;
    }
  };
  const baremeIndemnisationValue = useWatch({
    control,
    name: 'baremes.baremeIndemnisationId',
  });
  const baremeIndemnisation = baremesIndemnisation.find(
    (bareme) => bareme._id === baremeIndemnisationValue,
  );

  useEffect(() => {
    if (baremeIndemnisation?.source === 'FGTI') {
      setValue('baremes.baremeIndemnisationValueChoice', 'minimum');
    }
    if (baremeIndemnisationValue === '') {
      setValue('baremes.baremeIndemnisationValueChoice', null);
    }
  }, [baremeIndemnisation, setValue]);

  const body = (
    <FormProvider
      {...{ control, setValue, handleSubmit, reset, ...useFormReturn }}
    >
      <form onSubmit={handleSubmit(onSubmit, console.log)}>
        <Typography variant="h4" component="h2" marginTop={4}>
          {t('pages.Bareme.baremesTitle')}
        </Typography>
        <Typography marginBottom={2} color="GrayText">
          {t('pages.OrganizationConfiguration.baremes.subtitle')}
        </Typography>
        <Stack direction="column" marginTop={4}>
          <Typography variant="body1" fontWeight="bold" marginBottom={2}>
            {t('pages.OrganizationConfiguration.baremes.indemnisation')}
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            alignItems="center"
          >
            <SelectBareme
              control={control}
              baremes={baremesIndemnisation ?? []}
              name="baremes.baremeIndemnisationId"
              label={t(`bareme.type.Indemnisation.label.short`)}
              getBaremeSourceLabelFunction={(bareme) =>
                getIndemnisationBaremeSourceLabel(bareme, true)
              }
            />
            <RadioFormField
              control={control}
              name="baremes.baremeIndemnisationValueChoice"
              options={baremeIndemnisationValueChoices.map((value) => ({
                value,
                label: t(`bareme.type.IndemnisationValues.${value}.label`),
                isDisabled: (value) => isDisable(value) || !baremeIndemnisation,
              }))}
              row
            />
          </Stack>
          <Typography
            variant="body1"
            fontWeight="bold"
            marginTop={4}
            marginBottom={2}
          >
            {t('pages.OrganizationConfiguration.baremes.capitalisation')}
          </Typography>
          <SelectBareme
            control={control}
            name={'baremes.baremeCapitalisationId'}
            label={t('bareme.type.Capitalisation.label.short')}
            baremes={capitalisationBaremes ?? []}
            sx={{ width: 300 }}
          />
          <Typography
            variant="body1"
            fontWeight="bold"
            marginTop={4}
            marginBottom={2}
          >
            {t('pages.OrganizationConfiguration.baremes.rii')}
          </Typography>
          <SelectBareme
            control={control}
            baremes={baremesRII ?? []}
            name="baremes.baremeRIIId"
            label={t('bareme.type.RII.label.short')}
            getBaremeSourceLabelFunction={(bareme) =>
              getRIIBaremeSourceNameLabel(bareme, true)
            }
            sx={{ width: 300 }}
          />
          <ComputedPropsForm
            control={control}
            watchFields={['baremes.baremeRIIId']}
            compute={([baremeRIIId]) => ({
              hidden:
                baremesRII?.find((bareme) => bareme._id === baremeRIIId)
                  ?.source !== 'ONIAM',
            })}
            render={() => (
              <Stack direction="row" spacing={2} marginTop={1}>
                <FormControl>
                  <FormLabel>
                    {t('bareme.type.RIIValues.roundAge.label')}
                  </FormLabel>
                  <SelectFieldForm
                    control={control}
                    name="baremes.baremeRIIRoundAge"
                    allowNoSelection
                    noSelectionText={t(
                      'bareme.type.RIIValues.roundAge.noSelection',
                    )}
                    options={baremeRIIRoundChoices.map((choice) => ({
                      label: t(
                        `bareme.type.RIIValues.roundAge.options.${choice}`,
                      ),
                      value: choice,
                    }))}
                    sx={{ width: 380 }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t('bareme.type.RIIValues.roundTauxDFP.label')}
                  </FormLabel>
                  <SelectFieldForm
                    control={control}
                    name="baremes.baremeRIIRoundTauxDFP"
                    allowNoSelection
                    noSelectionText={t(
                      'bareme.type.RIIValues.roundTauxDFP.noSelection',
                    )}
                    options={baremeRIIRoundChoices.map((choice) => ({
                      label: t(
                        `bareme.type.RIIValues.roundTauxDFP.options.${choice}`,
                      ),
                      value: choice,
                    }))}
                    sx={{ width: 380 }}
                  />
                </FormControl>
              </Stack>
            )}
          />
          <Typography
            variant="body1"
            fontWeight="bold"
            marginTop={4}
            marginBottom={2}
          >
            {t('pages.OrganizationConfiguration.baremes.dftp.label')}
          </Typography>
          <TextFieldForm
            control={control}
            name="baremes.forfaitJourDFTP"
            label={t(
              'pages.OrganizationConfiguration.baremes.dftp.forfaitJour',
            )}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2 },
                suffix: '€',
              } as IMaskNumberProps,
            }}
            sx={{ alignSelf: 'start' }}
          />
        </Stack>
      </form>
    </FormProvider>
  );

  const footer = (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={2}
      style={{ width: '100%', marginTop: theme.spacing(isSidebar ? 0 : 5) }}
    >
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={() => {
          if (isSidebar) {
            closeSideBar();
            reset();
          } else {
            navigate(-1);
          }
        }}
      >
        {t('common.cancel')}
      </Button>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit(onSubmit)}
      >
        {t('common.save')}
      </Button>
    </Stack>
  );

  return !isSidebar ? (
    <Container maxWidth="md" style={{ padding: theme.spacing(2) }}>
      {header}
      {body}
      {footer}
    </Container>
  ) : (
    <Sidebar
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpen}
      justifyStart={true}
      closeSideBar={() => {
        closeSideBar();
        reset();
      }}
    />
  );
};
