import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Organization, OrganizationMember } from 'src/types/organization.type';

interface MemberDialogProps {
  members: OrganizationMember[];
  organization: Organization;
  open: boolean;
  onClose: () => void;
}

export const OrganizationMemberDialog: React.FC<MemberDialogProps> = ({
  members,
  organization,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = React.useState('');

  const filteredMembers = members.filter((member) =>
    `${member.firstName} ${member.lastName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase()),
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {t('organization.fields.organizationsList', {
          organizationName: organization?.name,
        })}
      </DialogTitle>
      <DialogContent
        sx={{ width: '700px', height: '700px', overflowY: 'hidden' }}
      >
        <Stack spacing={2}>
          <TextField
            placeholder={t('pages.Dashboard.updateVictimeRolesDialog.search')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ width: '80%' }}
          />
          <List>
            {filteredMembers.map((member) => (
              <ListItem key={member._id} sx={{ alignItems: 'flex-start' }}>
                <Box>
                  <Typography fontWeight="bold">
                    {`${member.firstName} ${member.lastName}`}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {member.email}
                  </Typography>
                </Box>
                <Typography
                  color="primary"
                  sx={{ alignSelf: 'center', marginLeft: 'auto' }}
                >
                  {t(`organization.fields.role.options.${member.role}.label`)}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
