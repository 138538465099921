import { Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { groupBy, upperFirst } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddOrUpdateMemberDialog } from 'src/components/organization/AddOrUpdateMemberDialog';
import { OrganizationInvitations } from 'src/components/organization/OrganizationInvitations';
import { OrganizationMembers } from 'src/components/organization/OrganizationMembers';
import { UpdateOrganizationDialog } from 'src/components/organization/UpdateOrganizationDialog';
import { SubscriptionConfiguration } from 'src/containers/SubscriptionConfiguration';
import { useAppDispatch } from 'src/hooks/store';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';
import { useInvitationsToOrganization } from 'src/hooks/store/organizationInvitation.hooks';
import { TOpenEditOrganization } from 'src/routes/OrganizationSwitch';
import { fetchAllBaremes } from 'src/slices/bareme';
import {
  deleteOrganization,
  updateOrganization,
} from 'src/slices/organization';
import { previewAddSubscriptionMemberAmount } from 'src/slices/subscription';
import {
  OrganizationMember,
  OrganizationMemberRole,
  UpdateOrganizationDto,
} from 'src/types/organization.type';

interface Props {
  setOpenEditOrganization: React.Dispatch<
    React.SetStateAction<TOpenEditOrganization>
  >;
}

export const OrganizationConfiguration: React.FC<Props> = ({
  setOpenEditOrganization,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [updateMemberDialog, setUpdateMemberDialog] =
    useState<OrganizationMember | null>(null);
  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState(false);
  const [isUpdateOrganizationDialogOpen, setIsUpdateOrganizationDialogOpen] =
    useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [
    isSubscriptionConfigurationDialogOpen,
    setIsSubscriptionConfigurationDialogOpen,
  ] = useState(false);
  const { organization } = useDisplayedOrganization();
  const invitations = useInvitationsToOrganization();
  const numberOfMembersByRole = useMemo(
    () =>
      Object.entries(
        groupBy(organization?.members ?? [], (member) => member.role),
      ).reduce(
        (accumulator, [role, members]) => ({
          ...accumulator,
          [role]: members.length,
        }),
        {} as Record<OrganizationMemberRole, number>,
      ),
    [organization],
  );

  const onClickAddMember = () => {
    setIsAddMemberDialogOpen(true);
  };

  const onClickUpdateMember = (member: OrganizationMember) => {
    setUpdateMemberDialog(member);
  };

  const onCloseAddOrUpdateMemberDialog = (createdInvitation?: true) => {
    if (isAddMemberDialogOpen) {
      setIsAddMemberDialogOpen(false);
    } else if (updateMemberDialog) {
      setUpdateMemberDialog(null);
    }
    if (createdInvitation && selectedTabIndex === 0) {
      setSelectedTabIndex(1);
    }
  };

  const onUpdateOrganization = (data: UpdateOrganizationDto) => {
    if (organization?._id) {
      dispatch(
        updateOrganization({
          organizationId: organization._id,
          data,
        }),
      );
      setIsUpdateOrganizationDialogOpen(false);
    }
  };

  const onDeleteOrganization = () => {
    if (organization?._id) {
      dispatch(
        deleteOrganization({
          organizationId: organization._id,
        }),
      );
    }
  };
  useEffect(() => {
    if (organization?._id) {
      dispatch(previewAddSubscriptionMemberAmount(organization._id));
    }
  }, [organization?._id]);

  useEffect(() => {
    dispatch(fetchAllBaremes());
  }, []);
  return (
    <>
      <Stack padding={4} spacing={2} height="100%">
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <Typography variant="h3">
              {upperFirst(organization?.name)}
            </Typography>
            <IconButton onClick={() => setIsUpdateOrganizationDialogOpen(true)}>
              <Edit />
            </IconButton>
          </Stack>
          <Button
            variant="outlined"
            onClick={() => setIsSubscriptionConfigurationDialogOpen(true)}
          >
            {t('pages.OrganizationConfiguration.manageSubscription.button')}
          </Button>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography>
            {t('pages.OrganizationConfiguration.numberOfMembersByRole.admin', {
              count:
                (numberOfMembersByRole.organization_admin || 0) +
                (numberOfMembersByRole.organization_owner || 0),
            })}
          </Typography>
          <Typography>
            {t(
              'pages.OrganizationConfiguration.numberOfMembersByRole.maxNumberOfUsers',
              {
                count:
                  (organization?.members.length || 0) +
                  (invitations.length || 0),
                total: (organization?.subscription.maxNumberOfUsers || 0) + 1,
              },
            )}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            onClick={onClickAddMember}
          >
            {t('pages.OrganizationConfiguration.addMember.button')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpenEditOrganization({
                Organization: organization ?? undefined,
              });
            }}
          >
            {t('pages.OrganizationConfiguration.calculationSettings.button')}
          </Button>
        </Stack>
        <Tabs
          value={selectedTabIndex}
          onChange={(_, newValue) => setSelectedTabIndex(newValue)}
        >
          <Tab
            label={t('pages.OrganizationConfiguration.tabs.members', {
              count: organization?.members.length || 0,
            })}
            value={0}
          />
          <Tab
            label={t('pages.OrganizationConfiguration.tabs.invitations', {
              count: invitations.length || 0,
            })}
            value={1}
          />
        </Tabs>
        <Box
          sx={{ display: selectedTabIndex === 0 ? undefined : 'none', flex: 1 }}
        >
          <OrganizationMembers onClickUpdateMember={onClickUpdateMember} />
        </Box>
        <Box
          sx={{ display: selectedTabIndex === 1 ? undefined : 'none', flex: 1 }}
        >
          <OrganizationInvitations />
        </Box>
      </Stack>
      {organization ? (
        <AddOrUpdateMemberDialog
          open={updateMemberDialog !== null || isAddMemberDialogOpen}
          onClose={onCloseAddOrUpdateMemberDialog}
          memberToUpdate={updateMemberDialog}
          organization={organization}
        />
      ) : null}
      <UpdateOrganizationDialog
        open={isUpdateOrganizationDialogOpen}
        onClose={() => setIsUpdateOrganizationDialogOpen(false)}
        organization={organization}
        onSubmit={onUpdateOrganization}
        onDelete={onDeleteOrganization}
      />
      <SubscriptionConfiguration
        open={isSubscriptionConfigurationDialogOpen}
        onClose={() => setIsSubscriptionConfigurationDialogOpen(false)}
        subscription={organization?.subscription}
        organization={organization}
      />
    </>
  );
};
