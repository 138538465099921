import { yupResolver } from '@hookform/resolvers/yup';
import { DeleteRounded, Help } from '@mui/icons-material';
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import i18next from 'i18next';
import { capitalize } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Sidebar from 'src/components/basic/Sidebar';
import { SelectBareme } from 'src/components/client/prejudiceFormComponents/Capitalisation/SelectBareme';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { SliderForm } from 'src/components/forms/SliderForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import {
  partResponsabiliteOptions,
  partResponsabiliteOptionsLabels,
} from 'src/constants/procedure';
import { theme } from 'src/constants/theme';
import {
  getIndemnisationBaremeSourceLabel,
  getRIIBaremeSourceNameLabel,
} from 'src/helpers/bareme/bareme';
import { fPercent } from 'src/helpers/formatNumber';
import { hideSensitiveFields } from 'src/helpers/organization';
import { getPrejudiceDefaultValues } from 'src/helpers/prejudices/automaticUpdate';
import { getCapitalisationBaremes } from 'src/helpers/prejudices/capitalisation';
import { getPrejudicesToUpdateAfterProcedureUpdate } from 'src/helpers/procedure';
import { dateString } from 'src/helpers/yup';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';
import { updateMultiplePrejudices } from 'src/slices/prejudice';
import { createProcedure, updateProcedure } from 'src/slices/procedure';
import {
  baremeSelectors,
  prejudiceSelectors,
  victimeIndirecteSelectors,
} from 'src/store/selectors';
import { UpdateMultiplePrejudicesDto } from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import * as yup from 'yup';
import { AutocompleteForm } from '../../../components/forms/AutocompleteForm';
import { DatePickerForm } from '../../../components/forms/DatePickerForm';
import { TextFieldForm } from '../../../components/forms/TextFieldForm';
import { fetchVictimeById } from '../../../slices/victime';
import {
  baremeIndemnisationValueChoices,
  baremeRIIRoundChoices,
  Jurisdiction,
  Procedure,
  ProcedureQuestion,
  ProcedureStatut,
  ProcedureType,
} from '../../../types/procedure.type';

type FormData = Pick<
  Procedure,
  | 'dateRedaction'
  | 'intitule'
  | 'resumeAffaire'
  | 'dateExpertise'
  | 'dateLiquidation'
  | 'dateConsolidation'
  | 'commentaireExpertise'
  | 'baremes'
  | 'statut'
  | 'partResponsabilite'
  | 'jurisdiction'
  | 'procedureType'
> & { tiersPayeurs: { name: string }[] };

interface Props {
  victime: Victime;
  procedure?: Procedure;
  answers?: ProcedureQuestion[];
  isSidebar?: boolean;
  isOpen: boolean | undefined;
  closeSideBar: () => void;
}
export const validationSchemaProcedure = (
  victime: Victime,
): yup.Schema<FormData> =>
  yup.object({
    dateRedaction: dateString()
      .typeError('À renseigner')
      .nullable()
      .minDate(
        undefined,
        i18next.t(
          'procedure.fields.dateRedaction.form.validationSchema.minDateAccident',
        ) || '',
        victime.dateAccident ? new Date(victime.dateAccident) : undefined,
      ),
    intitule: yup.string().optional().defined(),
    resumeAffaire: yup.string().defined(),
    dateExpertise: dateString().typeError('À renseigner').nullable(),
    dateLiquidation: dateString()
      .typeError('À renseigner')
      .nullable()
      .when(['procedureType'], ([procedureType], schema) => {
        schema = schema.minDate(
          undefined,
          capitalize(
            i18next.t(
              'procedure.fields.dateLiquidation.form.validationSchema.minDateAccident',
              {
                procedureType,
              },
            ) || '',
          ),
          victime.dateAccident ? new Date(victime.dateAccident) : undefined,
        );
        if (procedureType !== ProcedureType.provision) {
          return schema.required();
        }
        return schema;
      }),
    dateConsolidation: dateString()
      .typeError('À renseigner')
      .optional()
      .nullable()
      .minDate(
        undefined,
        i18next.t(
          'procedure.fields.dateConsolidation.form.validationSchema.minDateAccident',
        ) || '',
        victime.dateAccident ? new Date(victime.dateAccident) : undefined,
      )
      .when(
        ['dateLiquidation', 'procedureType'],
        ([dateLiquidation, procedureType], schema) => {
          if (
            !!dateLiquidation &&
            !isNaN(new Date(dateLiquidation).getTime())
          ) {
            return schema
              .optional()
              .nullable()
              .maxDate(
                'dateLiquidation',
                i18next.t(
                  'procedure.fields.dateConsolidation.form.validationSchema.maxDateLiquidation',
                  {
                    procedureType,
                  },
                ) || '',
              );
          } else {
            return schema;
          }
        },
      ),
    commentaireExpertise: yup.string().defined(),
    baremes: yup.object().shape({
      baremeIndemnisationId: yup.string().nullable().optional(),
      baremeIndemnisationValueChoice: yup
        .string()
        .nullable()
        .optional()
        .oneOf(baremeIndemnisationValueChoices)
        .when('baremeIndemnisationId', (baremeIndemnisationId, schema) =>
          typeof baremeIndemnisationId === 'string' &&
          baremeIndemnisationId !== 'noSelectionText'
            ? schema.required('Veuillez choisir une option pour le barème.')
            : schema.optional(),
        ),
      baremeRIIId: yup.string().nullable().optional(),
      baremeCapitalisationId: yup.string().nullable().optional(),
      baremeRIIRoundAge: yup
        .string()
        .oneOf(baremeRIIRoundChoices)
        .nullable()
        .optional(),
      baremeRIIRoundTauxDFP: yup
        .string()
        .oneOf(baremeRIIRoundChoices)
        .nullable()
        .optional(),
      forfaitJourDFTP: yup.number().nullable().optional(),
    }),
    statut: yup.string().required().oneOf(Object.values(ProcedureStatut)),
    partResponsabilite: yup
      .number()
      .transform((value) => value / 100)
      .min(0)
      .max(1.0)
      .required(),
    procedureType: yup
      .string()
      .nullable()
      .required()
      .oneOf(Object.values(ProcedureType)),
    jurisdiction: yup
      .string()
      .optional()
      .nullable()
      .oneOf(Object.values(Jurisdiction)),
    tiersPayeurs: yup
      .array()
      .defined()
      .of(yup.object({ name: yup.string().required() })),
  });
export const ProcedureEdition: React.FC<Props> = ({
  victime,
  procedure,
  isSidebar,
  isOpen,
  closeSideBar,
  answers,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { prejudices, baremes, victimesIndirectes } = useAppSelector(
    (state) => ({
      prejudices: prejudiceSelectors.selectAll(state),
      baremes: baremeSelectors.selectAll(state),
      victimesIndirectes: victimeIndirecteSelectors.selectAll(state),
    }),
  );
  const { organization, organizationId } = useDisplayedOrganization();
  const capitalisationBaremes = useMemo(
    () => getCapitalisationBaremes(baremes, victime.sexe),
    [baremes],
  );
  const updatePrejudicesAfterProcedureUpdate = async (
    oldProcedure: Procedure,
    newProcedure: Procedure,
  ) => {
    const prejudicesToUpdate = getPrejudicesToUpdateAfterProcedureUpdate({
      oldProcedure,
      newProcedure,
      prejudices,
    });

    const updatePrejudicesDto: UpdateMultiplePrejudicesDto = {
      prejudices: prejudicesToUpdate.map((prejudice) => {
        const prejudiceDefaultValues = getPrejudiceDefaultValues({
          prejudice,
          procedure: newProcedure,
          victime,
          baremes,
          victimesIndirectes,
        });
        return {
          _id: prejudice._id,
          formData: prejudiceDefaultValues,
        };
      }),
    };
    try {
      await dispatch(
        updateMultiplePrejudices({
          procedureId: newProcedure._id,
          dto: updatePrejudicesDto,
        }),
      ).unwrap();
    } catch (e) {
      //TODO : Add better error message
      console.error(e);
    }
  };

  const onSubmit = async (values: FormData) => {
    if (!procedure) {
      if (!answers) {
        // TODO: Remove exception and use toast
        throw new Error(
          `Unable to create folder with undefined 'answers' (answers: ${answers})`,
        );
      }

      const createdProcedure = await dispatch(
        createProcedure({
          victimeId: victime._id,
          data: {
            ...values,
            inTrash: false,
            isInitiated: false,
            answers,
            hospitalisations: [],
            tiersPayeurs: values.tiersPayeurs.map(
              (tierPayeur) => tierPayeur.name,
            ),
          },
        }),
      ).unwrap();
      await dispatch(fetchVictimeById(victime._id));
      navigate(
        `/organization/${organizationId}/client/${victime._id}/procedures/${createdProcedure._id}`,
      );
    } else {
      const oldProcedure = procedure;
      const newProcedure = await dispatch(
        updateProcedure({
          procedureId: procedure._id,
          victimeId: victime._id,
          data: {
            ...values,
            hospitalisations: procedure?.hospitalisations || [],
            tiersPayeurs: values.tiersPayeurs.map(
              (tierPayeur) => tierPayeur.name,
            ),
          },
        }),
      ).unwrap();
      await updatePrejudicesAfterProcedureUpdate(oldProcedure, newProcedure);
      dispatch(fetchVictimeById(victime._id));
    }

    if (isSidebar) {
      closeSideBar();
      reset();
    } else {
      navigate(-1);
    }
  };

  const initialValues: FormData = {
    intitule: procedure?.intitule || '',
    resumeAffaire: procedure?.resumeAffaire || '',
    commentaireExpertise: procedure?.commentaireExpertise || '',
    dateRedaction: procedure?.dateRedaction || null,
    dateLiquidation: procedure?.dateLiquidation || null,
    dateExpertise: procedure?.dateExpertise || null,
    dateConsolidation: procedure?.dateConsolidation || null,
    baremes: {
      baremeIndemnisationId:
        procedure?.baremes?.baremeIndemnisationId ||
        organization?.baremes?.baremeIndemnisationId ||
        null,
      baremeIndemnisationValueChoice:
        procedure?.baremes?.baremeIndemnisationValueChoice ||
        organization?.baremes?.baremeIndemnisationValueChoice ||
        null,
      baremeRIIId:
        procedure?.baremes?.baremeRIIId ||
        organization?.baremes?.baremeRIIId ||
        null,
      baremeCapitalisationId:
        procedure?.baremes?.baremeCapitalisationId ||
        organization?.baremes?.baremeCapitalisationId ||
        null,
      baremeRIIRoundAge:
        procedure?.baremes?.baremeRIIRoundAge ||
        organization?.baremes?.baremeRIIRoundAge ||
        null,
      baremeRIIRoundTauxDFP:
        procedure?.baremes?.baremeRIIRoundTauxDFP ||
        organization?.baremes?.baremeRIIRoundTauxDFP ||
        null,
      forfaitJourDFTP:
        procedure?.baremes?.forfaitJourDFTP ||
        organization?.baremes?.forfaitJourDFTP ||
        null,
    },
    statut: procedure?.statut || ProcedureStatut.OUVERT,
    partResponsabilite: (procedure?.partResponsabilite || 1) * 100,
    tiersPayeurs:
      procedure?.tiersPayeurs.map((tierPayeur) => ({ name: tierPayeur })) || [],
    procedureType: procedure?.procedureType,
    jurisdiction: procedure?.jurisdiction,
  };

  const { control, setValue, handleSubmit, reset, ...useFormReturn } =
    useForm<FormData>({
      defaultValues: initialValues,
      resolver: yupResolver(validationSchemaProcedure(victime)),
      mode: 'onTouched',
    });

  const {
    fields: tiersPayeursFields,
    append: appendTierPayeur,
    remove: removeTierPayeur,
  } = useFieldArray({
    name: 'tiersPayeurs',
    control,
  });

  useEffect(() => {
    if (isOpen) {
      reset(initialValues);
    }
  }, [isOpen]);
  const shouldHideSensitiveFields =
    organization && hideSensitiveFields(organization);
  const header = (
    <Typography variant="h4" textAlign="center" marginY={2}>
      {procedure
        ? t('procedure.form.title.edit')
        : t('procedure.form.title.create')}
    </Typography>
  );

  const baremesIndemnisation = baremes.filter(
    (bareme) =>
      bareme.type === 'Indemnisation' &&
      ['Mornet', 'FGTI', 'ONIAM'].includes(bareme.source),
  );

  const baremesRII = baremes.filter(
    (bareme) =>
      bareme.type === 'RII' &&
      ['Mornet', 'FGTI', 'ONIAM'].includes(bareme.source),
  );

  const isDisable = (value: string) => {
    switch (value) {
      case 'minimum':
        return false;
      case 'average':
      case 'maximum':
        return baremeIndemnisation?.source === 'FGTI';
      default:
        return true;
    }
  };
  const baremeIndemnisationValue = useWatch({
    control,
    name: 'baremes.baremeIndemnisationId',
  });
  const baremeIndemnisation = baremesIndemnisation.find(
    (bareme) => bareme._id === baremeIndemnisationValue,
  );

  useEffect(() => {
    if (baremeIndemnisation?.source === 'FGTI') {
      setValue('baremes.baremeIndemnisationValueChoice', 'minimum');
    }
    if (baremeIndemnisationValue === '') {
      setValue('baremes.baremeIndemnisationValueChoice', null);
    }
  }, [baremeIndemnisation, setValue]);

  const body = (
    <FormProvider
      {...{ control, setValue, handleSubmit, reset, ...useFormReturn }}
    >
      <form onSubmit={handleSubmit(onSubmit, console.log)}>
        <Stack spacing={2}>
          <Typography variant="h4" component="h2">
            Informations
          </Typography>
          <SelectFieldForm
            control={control}
            name="procedureType"
            variant="outlined"
            label={t('procedure.fields.procedureType.form.label')}
            fullWidth
            InputLabelProps={{ shrink: true }}
            margin="normal"
            required
            options={Object.values(ProcedureType).map((procedureType) => ({
              value: procedureType,
              label: t(
                `procedure.fields.procedureType.options.${procedureType}`,
              ),
            }))}
          />
          <TextFieldForm
            name="intitule"
            control={control}
            variant="outlined"
            label={t('procedure.fields.intitule.form.label')}
            fullWidth
            InputLabelProps={{ shrink: true }}
            placeholder={t('procedure.fields.intitule.form.placeholder') || ''}
          />
          <AutocompleteForm
            control={control}
            name="jurisdiction"
            TextFieldProps={{
              label: t('procedure.fields.jurisdiction.form.label'),
            }}
            disablePortal
            getOptionLabel={(option) =>
              Object.values(Jurisdiction).includes(option as Jurisdiction)
                ? t(`procedure.fields.jurisdiction.options.${option}`) ?? option
                : option
            }
            options={Object.values(Jurisdiction)}
            blurOnSelect={true}
            freeSolo
          />
          {!!procedure && (
            <SelectFieldForm
              control={control}
              name="statut"
              variant="outlined"
              label={t('procedure.fields.statut.form.label')}
              fullWidth
              InputLabelProps={{ shrink: true }}
              margin="normal"
              required
              options={Object.values(ProcedureStatut).map((statut) => ({
                value: statut,
                label: t(`procedure.fields.statut.options.${statut}`),
              }))}
            />
          )}
          {shouldHideSensitiveFields ? null : (
            <TextFieldForm
              name="resumeAffaire"
              control={control}
              variant="outlined"
              label={t('procedure.fields.resumeAffaire.form.label')}
              fullWidth
              InputLabelProps={{ shrink: true }}
              multiline
            />
          )}
          {shouldHideSensitiveFields ? null : (
            <TextFieldForm
              name="commentaireExpertise"
              control={control}
              variant="outlined"
              label={t('procedure.fields.commentaireExpertise.form.label')}
              fullWidth
              InputLabelProps={{ shrink: true }}
              multiline
            />
          )}
          <Stack
            marginY={2}
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
          >
            <DatePickerForm
              name="dateRedaction"
              control={control}
              label={t('procedure.fields.dateRedaction.form.label')}
              TextFieldProps={{ InputLabelProps: { shrink: true } }}
              minDate={
                victime.dateAccident
                  ? new Date(victime.dateAccident)
                  : undefined
              }
            />
            <DatePickerForm
              name="dateExpertise"
              control={control}
              label={t('procedure.fields.dateExpertise.form.label')}
              TextFieldProps={{ InputLabelProps: { shrink: true } }}
            />
            <ComputedPropsForm
              control={control}
              watchFields={['procedureType']}
              compute={([procedureType]) => ({
                props: {
                  procedureType: procedureType
                    ? procedureType
                    : ProcedureType.liquidation,
                },
              })}
              render={({ procedureType }) => (
                <DatePickerForm
                  name="dateLiquidation"
                  control={control}
                  label={`${capitalize(
                    t('procedure.fields.dateLiquidation.form.label', {
                      procedureType: procedureType,
                    }) || '',
                  )} *`}
                  minDate={
                    victime.dateAccident
                      ? new Date(victime.dateAccident)
                      : undefined
                  }
                  TextFieldProps={{ InputLabelProps: { shrink: true } }}
                  disableFuture={false}
                />
              )}
            />
          </Stack>
          <DatePickerForm
            name="dateConsolidation"
            control={control}
            label={t('procedure.fields.dateConsolidation.form.label')}
            TextFieldProps={{ InputLabelProps: { shrink: true } }}
            minDate={
              victime.dateAccident ? new Date(victime.dateAccident) : undefined
            }
            maxDate={
              victime?.dateDeces ? new Date(victime?.dateDeces) : undefined
            }
            sx={{ marginBottom: 2 }}
          />
          <Stack direction="row" spacing={2}>
            <Typography variant="h4" component="h2">
              {t('procedure.fields.partResponsabilite.title')}
            </Typography>
            <Tooltip title={t('partResponsabilite.tooltip')} placement="right">
              <IconButton>
                <Help />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={'space-between'}
          >
            <SliderForm
              control={control}
              name="partResponsabilite"
              marks={partResponsabiliteOptions.map((value) => ({
                value,
                label: partResponsabiliteOptionsLabels[value]?.fraction,
              }))}
              displayAsPercentage
              min={0}
              max={100}
              step={null}
              valueLabelFormat={(value) => fPercent(value, 2)}
              sx={{ marginRight: 4 }}
            />
            <TextFieldForm
              name="partResponsabilite"
              control={control}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{ width: '105px' }}
              InputProps={{
                inputComponent: MaskNumber as any,
                inputProps: {
                  numberMask: { scale: 2, min: 0, max: 100 },
                  suffix: '%',
                } as IMaskNumberProps,
              }}
            />
          </Stack>
          <Typography variant="h4" component="h2">
            {t('procedure.fields.tiersPayeurs.form.title')}
          </Typography>
          {procedure?.isInitiated && tiersPayeursFields.length === 0 && (
            <Typography variant="subtitle2" color="text.secondary">
              {t('procedure.fields.tiersPayeurs.form.noTiersPayeurs')}
            </Typography>
          )}
          {tiersPayeursFields.map((field, index) => (
            <TextFieldForm
              key={field.id}
              control={control}
              name={`tiersPayeurs.${index}.name`}
              label={t('procedure.fields.tiersPayeurs.form.name.label')}
              fullWidth
              disabled={procedure?.tiersPayeurs.includes(field.name) || false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => removeTierPayeur(index)}
                    >
                      <DeleteRounded />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              required
            />
          ))}
          <Button onClick={() => appendTierPayeur({ name: '' })}>
            {t('procedure.fields.tiersPayeurs.form.add')}
          </Button>
        </Stack>
        <Typography variant="h4" component="h2" marginTop={4}>
          {t('pages.Bareme.baremesTitle')}
        </Typography>
        <Typography marginBottom={2} color="GrayText">
          {t('pages.Procedure.baremes.subtitle')}
        </Typography>
        <Stack direction="column" marginTop={4}>
          <Typography variant="body1" fontWeight="bold" marginBottom={2}>
            {t('pages.Procedure.baremes.indemnisation.label')}
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            alignItems="center"
          >
            <SelectBareme
              control={control}
              baremes={baremesIndemnisation ?? []}
              name="baremes.baremeIndemnisationId"
              label={t(`bareme.type.Indemnisation.label.short`)}
              getBaremeSourceLabelFunction={(bareme) =>
                getIndemnisationBaremeSourceLabel(bareme, true)
              }
            />
            <RadioFormField
              control={control}
              name="baremes.baremeIndemnisationValueChoice"
              options={baremeIndemnisationValueChoices.map((value) => ({
                value,
                label: t(`bareme.type.IndemnisationValues.${value}.label`),
                isDisabled: (value) => isDisable(value) || !baremeIndemnisation,
              }))}
              row
            />
          </Stack>
          <Typography
            variant="body1"
            fontWeight="bold"
            marginTop={4}
            marginBottom={2}
          >
            {t('pages.Procedure.baremes.capitalisation.label')}
          </Typography>
          <SelectBareme
            control={control}
            name={'baremes.baremeCapitalisationId'}
            label={t('bareme.type.Capitalisation.label.short')}
            baremes={capitalisationBaremes ?? []}
            sx={{ width: 300 }}
          />
          <Typography
            variant="body1"
            fontWeight="bold"
            marginTop={4}
            marginBottom={2}
          >
            {t('pages.Procedure.baremes.rii.label')}
          </Typography>
          <SelectBareme
            control={control}
            baremes={baremesRII ?? []}
            name="baremes.baremeRIIId"
            label={t('bareme.type.RII.label.short')}
            getBaremeSourceLabelFunction={(bareme) =>
              getRIIBaremeSourceNameLabel(bareme, true)
            }
            sx={{ width: 300 }}
          />
          <ComputedPropsForm
            control={control}
            watchFields={['baremes.baremeRIIId']}
            compute={([baremeRIIId]) => ({
              hidden:
                baremesRII?.find((bareme) => bareme._id === baremeRIIId)
                  ?.source !== 'ONIAM',
            })}
            render={() => (
              <Stack direction="row" spacing={2} marginTop={1}>
                <FormControl>
                  <FormLabel>
                    {t('bareme.type.RIIValues.roundAge.label')}
                  </FormLabel>
                  <SelectFieldForm
                    control={control}
                    name="baremes.baremeRIIRoundAge"
                    allowNoSelection
                    noSelectionText={t(
                      'bareme.type.RIIValues.roundAge.noSelection',
                    )}
                    options={baremeRIIRoundChoices.map((choice) => ({
                      label: t(
                        `bareme.type.RIIValues.roundAge.options.${choice}`,
                      ),
                      value: choice,
                    }))}
                    sx={{ width: 380 }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t('bareme.type.RIIValues.roundTauxDFP.label')}
                  </FormLabel>
                  <SelectFieldForm
                    control={control}
                    name="baremes.baremeRIIRoundTauxDFP"
                    allowNoSelection
                    noSelectionText={t(
                      'bareme.type.RIIValues.roundTauxDFP.noSelection',
                    )}
                    options={baremeRIIRoundChoices.map((choice) => ({
                      label: t(
                        `bareme.type.RIIValues.roundTauxDFP.options.${choice}`,
                      ),
                      value: choice,
                    }))}
                    sx={{ width: 380 }}
                  />
                </FormControl>
              </Stack>
            )}
          />
          <Typography
            variant="body1"
            fontWeight="bold"
            marginTop={4}
            marginBottom={2}
          >
            {t('pages.Procedure.baremes.dftp.label')}
          </Typography>
          <TextFieldForm
            control={control}
            name="baremes.forfaitJourDFTP"
            label={t('pages.Procedure.baremes.dftp.forfaitJour.label')}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2 },
                suffix: '€',
              } as IMaskNumberProps,
            }}
            sx={{ alignSelf: 'start' }}
          />
        </Stack>
      </form>
    </FormProvider>
  );

  const footer = (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={2}
      style={{ width: '100%', marginTop: theme.spacing(isSidebar ? 0 : 5) }}
    >
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={() => {
          if (isSidebar) {
            closeSideBar();
            reset();
          } else {
            navigate(-1);
          }
        }}
      >
        {t('common.cancel')}
      </Button>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit(onSubmit)}
      >
        {t('common.save')}
      </Button>
    </Stack>
  );

  return !isSidebar ? (
    <Container maxWidth="md" style={{ padding: theme.spacing(2) }}>
      {header}
      {body}
      {footer}
    </Container>
  ) : (
    <Sidebar
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpen}
      closeSideBar={() => {
        closeSideBar();
        reset();
      }}
    />
  );
};
