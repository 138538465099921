import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import {
  AnswerOrganizationInvitationDto,
  CreateOrganizationInvitationDto,
  OrganizationInvitation,
  OrganizationInvitationStatus,
  UpdateOrganizationInvitationDto,
} from 'src/types/organizationInvitation.type';
import { apiRequest } from '../helpers/api';
import {
  fetchOrganization,
  fetchSubscriptionByOrganizationId,
} from './organization';

/* Thunks */
export const fetchAllInvitationsbyReceiver = createAsyncThunk(
  'organizationInvitations/fetchAllByReceiver',
  async () => {
    return await apiRequest<OrganizationInvitation[]>(
      'GET',
      '/organization-invitations',
    );
  },
);

export const fetchAllOrganizationInvitations = createAsyncThunk(
  'organizationInvitations/fetchAll',
  async (organizationId: string) => {
    return await apiRequest<OrganizationInvitation[]>(
      'GET',
      `/organization/${organizationId}/invitations`,
    );
  },
);

export const createOrganizationInvitation = createAsyncThunk(
  'organizationInvitations/create',
  async (
    payload: {
      organizationId: string;
      data: CreateOrganizationInvitationDto;
    },
    { dispatch },
  ) => {
    const { data, organizationId } = payload;
    const result = await apiRequest<OrganizationInvitation>(
      'POST',
      `/organization/${organizationId}/invitations`,
      undefined,
      data,
    );
    dispatch(fetchSubscriptionByOrganizationId(organizationId));
    return result;
  },
);

export const resendOrganizationInvitation = createAsyncThunk(
  'organizationInvitations/resend',
  async (payload: {
    organizationId: string;
    organizationInvitationId: string;
  }) => {
    const { organizationId, organizationInvitationId } = payload;
    return await apiRequest<OrganizationInvitation>(
      'PUT',
      `/organization/${organizationId}/invitations/${organizationInvitationId}/resend`,
    );
  },
);

export const updateOrganizationInvitation = createAsyncThunk(
  'organizationInvitations/update',
  async (payload: {
    organizationId: string;
    organizationInvitationId: string;
    data: UpdateOrganizationInvitationDto;
  }) => {
    const { organizationId, organizationInvitationId, data } = payload;

    return await apiRequest<OrganizationInvitation>(
      'PUT',
      `/organization/${organizationId}/invitations/${organizationInvitationId}`,
      undefined,
      data,
    );
  },
);

export const deleteOrganizationInvitation = createAsyncThunk(
  'organizationInvitations/delete',
  async (payload: {
    organizationId: string;
    organizationInvitationId: string;
  }) => {
    const { organizationId, organizationInvitationId } = payload;

    return await apiRequest<{ id: string }>(
      'DELETE',
      `/organization/${organizationId}/invitations/${organizationInvitationId}`,
    );
  },
);

export const answerOrganizationInvitationToOrganization = createAsyncThunk(
  'organizationInvitations/answerToOrganization',
  async (
    payload: {
      organizationInvitationId: string;
      dto: AnswerOrganizationInvitationDto;
    },
    { dispatch },
  ) => {
    const { organizationInvitationId, dto } = payload;

    const result = await apiRequest<OrganizationInvitation>(
      'PUT',
      `/organization/invitations/${organizationInvitationId}/answer`,
      undefined,
      dto,
    );
    if (
      result.status === dto.status &&
      dto.status === OrganizationInvitationStatus.ACCEPTED &&
      result.organization._id
    ) {
      dispatch(fetchOrganization(result.organization._id));
    }
    return result;
  },
);

/* Shared reducers */
const sharedReducers = {
  pending: (state: any) => {
    state.isLoading = true;
    state.error = null;
  },
  rejected: (state: any, { error }: { error: SerializedError }) => {
    state.isLoading = false;
    state.error = error.message || 'error';
  },
  updateOrganizationInvitation: (state: any, action: PayloadAction<any>) => {
    state.isLoading = false;
    state.organizationInvitations = state.organizationInvitations.map(
      (organizationInvitation: any) => {
        if (organizationInvitation._id === action.payload._id) {
          return action.payload;
        }
        return organizationInvitation;
      },
    );
  },
};

/* Adapter */
export const organizationInvitationsAdapter =
  createEntityAdapter<OrganizationInvitation>({
    selectId: (organizationInvitation) => organizationInvitation._id,
  });

/* Slice */
interface State {
  organizationInvitations: OrganizationInvitation[];
  isLoading: boolean;
  isInitialLoading: boolean;
  error: string | null | undefined;
  isPaymentMethodMissingError: boolean;
}
const organizationInvitationsSlice = createSlice({
  name: 'organizationInvitations',
  initialState: organizationInvitationsAdapter.getInitialState<State>({
    organizationInvitations: [],
    isLoading: false,
    isInitialLoading: true,
    error: undefined,
    isPaymentMethodMissingError: false,
  }),
  reducers: {
    setOrganizationInvitation: (
      state,
      action: PayloadAction<OrganizationInvitation>,
    ) => {
      organizationInvitationsAdapter.upsertOne(state, action.payload);
    },
    removeOne: (state, action: PayloadAction<EntityId>) => {
      organizationInvitationsAdapter.removeOne(state, action.payload);
    },
    removePaymentMethodMissingError: (state) => {
      state.isPaymentMethodMissingError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInvitationsbyReceiver.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllInvitationsbyReceiver.rejected, (state, { error }) => {
        state.isLoading = false;
        state.isInitialLoading = false;
        state.error = error.message || 'error';
      })
      .addCase(fetchAllInvitationsbyReceiver.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isInitialLoading = false;
        organizationInvitationsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchAllOrganizationInvitations.pending, sharedReducers.pending)
      .addCase(
        fetchAllOrganizationInvitations.rejected,
        sharedReducers.rejected,
      )
      .addCase(fetchAllOrganizationInvitations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organizationInvitations = action.payload;
      })
      .addCase(createOrganizationInvitation.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isPaymentMethodMissingError = false;
      })
      .addCase(createOrganizationInvitation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'error';
        if (action.error.code === '402') {
          state.isPaymentMethodMissingError = true;
        }
      })
      .addCase(createOrganizationInvitation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organizationInvitations.push(action.payload);
        state.isPaymentMethodMissingError = false;
      })
      .addCase(resendOrganizationInvitation.pending, sharedReducers.pending)
      .addCase(resendOrganizationInvitation.rejected, sharedReducers.rejected)
      .addCase(
        resendOrganizationInvitation.fulfilled,
        sharedReducers.updateOrganizationInvitation,
      )
      .addCase(updateOrganizationInvitation.pending, sharedReducers.pending)
      .addCase(updateOrganizationInvitation.rejected, sharedReducers.rejected)
      .addCase(
        updateOrganizationInvitation.fulfilled,
        sharedReducers.updateOrganizationInvitation,
      )
      .addCase(deleteOrganizationInvitation.pending, sharedReducers.pending)
      .addCase(deleteOrganizationInvitation.rejected, sharedReducers.rejected)
      .addCase(deleteOrganizationInvitation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organizationInvitations = state.organizationInvitations.filter(
          (organizationInvitation) =>
            organizationInvitation._id !==
            action.meta.arg.organizationInvitationId,
        );
      })
      .addCase(
        answerOrganizationInvitationToOrganization.pending,
        sharedReducers.pending,
      )
      .addCase(
        answerOrganizationInvitationToOrganization.rejected,
        sharedReducers.rejected,
      )
      .addCase(
        answerOrganizationInvitationToOrganization.fulfilled,
        (state, action) => {
          state.isLoading = false;
          organizationInvitationsAdapter.upsertOne(state, action.payload);
        },
      );
  },
});

export const {
  setOrganizationInvitation,
  removeOne,
  removePaymentMethodMissingError,
} = organizationInvitationsSlice.actions;

export default organizationInvitationsSlice.reducer;
